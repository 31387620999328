import 'ag-grid-community/styles/ag-grid.css'

import { DEFAULT_LIST_SIZE, useCSR } from '@apeiron/library'
import { Box, styled } from '@mui/material'
import TickSVG from '@public/icons/icon-tick.svg'
import LoadingNow from '@src/components/share/generic/LoadingNow'
import { Pagination } from '@src/components/share/generic/Pagination'
import { SearchEmptyState } from '@src/components/share/generic/SearchEmptyState'
import { useDataGridContext } from '@src/contexts/share/DataGridContext'
import {
  ColDef,
  Column,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent
} from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import {
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 500px;
  width: 100%;
`

const StyledAgGrid = styled(AgGridReact)`
  width: 100%;
  flex: 1;
  overflow: hidden;
  border-radius: 10px;

  .custom-ag-cell-left {
    justify-content: flex-start;
  }
  .custom-ag-cell-center {
    justify-content: center;
  }
  .custom-ag-cell-right {
    justify-content: flex-end;
  }
  .custom-ag-header-left {
    .ag-header-cell-label {
      justify-content: flex-start;
    }
  }
  .custom-ag-header-left {
    .ag-header-cell-label {
      justify-content: flex-start;
    }
  }
  .custom-ag-header-center {
    .ag-header-cell-label {
      justify-content: center;
    }
  }
  .custom-ag-header-right {
    .ag-header-cell-label {
      justify-content: flex-end;
    }
  }
  .ag-row-hover {
    background-color: #222225;
  }
  .ag-header {
    font-size: 16px;
  }
  .ag-header-row {
    background-color: #27272a;
  }
  .ag-body-viewport {
    background-color: #0e0e12;
  }
  .ag-cell {
    display: flex;
    align-items: center;
  }
  .ag-checkbox-input-wrapper.ag-checked {
    --background-color: #005bd7;
    --border-color: #005bd7;
  }
  .ag-checkbox-input-wrapper {
    --background-color: #363636;
    --border-color: #7a7979;
    width: 18px;
    height: 18px;
  }
  .ag-checkbox-input-wrapper::after {
    content: '';
    background: var(--background-color);
    border: 1px solid var(--border-color);
    pointer-events: none;
    height: 18px;
    width: 18px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }
  .ag-checkbox-input-wrapper.ag-checked::after {
    content: url(${TickSVG.src});
  }
`

const StyledPagination = styled(Pagination)`
  .Custom-Page-Button {
    background-color: #2a2a2b;
  }
`

const LoadingCover = styled(Box)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const DataGridLoadingOverlay = () => {
  return (
    <LoadingCover>
      <LoadingNow />
    </LoadingCover>
  )
}

export const BasicApeironPagingDataGrid = ({
  className,
  pageSize = DEFAULT_LIST_SIZE,
  children,
  loading,
  rowData,
  totalPage,
  currentPage,
  onNextPage,
  onPreviousPage,
  onSubmitPage,
  onSelectionChanged,
  defaultColDef,
  onGridReady,
  emptyConfig,
  ...rest
}: BasicProps) => {
  const { t } = useTranslation()

  const handleOnNoRowsOverlay = useCallback(
    () => (
      <SearchEmptyState
        buyURL={emptyConfig?.buyURL}
        rentURL={emptyConfig?.rentURL}
        description={t('common.no_data')}
      />
    ),
    [emptyConfig?.buyURL, emptyConfig?.rentURL, t]
  )

  return (
    <Container className={className}>
      <StyledAgGrid
        cacheBlockSize={pageSize}
        className='Custom-Grid'
        headerHeight={56}
        rowData={rowData}
        rowHeight={80}
        loading={loading}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        onSelectionChanged={onSelectionChanged}
        loadingOverlayComponent={DataGridLoadingOverlay}
        noRowsOverlayComponent={handleOnNoRowsOverlay}
        {...rest}
      />
      {children}
      <StyledPagination
        className='Custom-Pagination'
        nextDisabled={currentPage === totalPage}
        prevDisabled={currentPage === 1}
        totalPage={totalPage}
        value={currentPage}
        onNext={onNextPage}
        onPrev={onPreviousPage}
        onSubmit={onSubmitPage}
      />
    </Container>
  )
}

export const ApeironPagingDataGrid = (props: Props) => {
  const {
    autoFetch = true,
    children,
    className,
    emptyConfig,
    gridRef,
    pageSize = DEFAULT_LIST_SIZE,
    selectedIds,
    fetch,
    onSelectionChangedByPage,
    ...rest
  } = props

  const { showGrid, setShowGrid } = useDataGridContext()

  const [selectedByPage, setSelectedByPage] = useState<{
    [key: string]: SelectedItem[]
  }>({})

  const [gridApi, setGridApi] = useState<GridApi | null>(null)

  const [currentPage, setCurrentPage] = useState<number>(1)

  const [loading, setLoading] = useState<boolean>(true)

  const [rowData, setRowData] = useState<unknown[]>([])

  const [totalPage, setTotalPage] = useState<number>(0)

  const isCSR = useCSR()

  const handleOnCheckVisibility = useCallback(async () => {
    const { total = 0 } = await fetch(1, 1)

    setShowGrid(total > 0)
  }, [fetch, setShowGrid])

  const handleOnFetchData = useCallback(
    async (api: GridApi, page: number) => {
      setLoading(true)
      try {
        const { data, total = 0 } = await fetch(page, pageSize)

        setRowData(data)
        setCurrentPage(page)
        setTotalPage(Math.max(Math.ceil(total / pageSize), 1))
        setGridApi(api)
      } finally {
        setLoading(false)
      }
    },
    [fetch, pageSize]
  )

  const handleOnGridReady = useCallback(
    (event: GridReadyEvent) => {
      const { api } = event

      if (api) {
        if (autoFetch) {
          handleOnFetchData(api, 1)
        }
      }
    },
    [autoFetch, handleOnFetchData]
  )

  const handleOnPreviousPage = useCallback(async () => {
    if (gridApi) {
      handleOnFetchData(gridApi, currentPage - 1)
    }
  }, [currentPage, gridApi, handleOnFetchData])

  const handleOnNextPage = useCallback(async () => {
    if (gridApi) {
      handleOnFetchData(gridApi, currentPage + 1)
    }
  }, [currentPage, gridApi, handleOnFetchData])

  const handleOnSubmitPage = useCallback(
    async (page: number) => {
      if (gridApi) {
        handleOnFetchData(gridApi, page)
      }
    },
    [gridApi, handleOnFetchData]
  )

  const setColumnVisible = useCallback(
    (key: string | Column, visible: boolean) => {
      if (gridApi) {
        gridApi.setColumnsVisible([key], visible)
      }
    },
    [gridApi]
  )

  const refreshData = useCallback(() => {
    if (gridApi) {
      handleOnFetchData(gridApi, 1)
    }
  }, [gridApi, handleOnFetchData])

  const deselectAll = useCallback(() => {
    if (gridApi) {
      gridApi.deselectAll()
    }
  }, [gridApi])

  useEffect(() => {
    handleOnCheckVisibility()
  }, [])

  useImperativeHandle(
    gridRef,
    () => {
      return {
        setColumnVisible,
        refreshData,
        deselectAll
      }
    },
    [setColumnVisible, refreshData, deselectAll]
  )

  const defaultColDef = {
    lockVisible: true,
    sortable: false,
    suppressMovable: true
  }

  useEffect(() => {
    if (selectedIds && gridApi && selectedIds.length > 0) {
      gridApi.forEachNode(node => {
        if (selectedIds.includes(String(node.data.id))) {
          node.setSelected(true)
        }
      })
    }
  }, [gridApi, selectedIds, currentPage])

  const handleOnSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      const currentPageSelectedRows = event.api.getSelectedRows()

      const pageIndexes = Object.keys(selectedByPage)

      let selectedRows = []

      if (pageIndexes.length === 0) {
        selectedRows = currentPageSelectedRows
      } else {
        selectedRows = pageIndexes.reduce(
          (acc: SelectedItem[], cur: string) => {
            if (Number(cur) === currentPage) {
              return [...acc, ...currentPageSelectedRows]
            }

            return [...acc, ...selectedByPage[cur]]
          },
          []
        )
      }

      if (onSelectionChangedByPage) {
        onSelectionChangedByPage(selectedRows)
      }

      setSelectedByPage({
        ...selectedByPage,
        [currentPage]: currentPageSelectedRows
      })
    },
    [currentPage, onSelectionChangedByPage, selectedByPage]
  )

  return isCSR && showGrid ? (
    <BasicApeironPagingDataGrid
      className={className}
      pageSize={pageSize}
      loading={loading}
      rowData={rowData}
      totalPage={totalPage}
      currentPage={currentPage}
      onNextPage={handleOnNextPage}
      onPreviousPage={handleOnPreviousPage}
      onSubmitPage={handleOnSubmitPage}
      onSelectionChanged={handleOnSelectionChanged}
      defaultColDef={defaultColDef}
      onGridReady={handleOnGridReady}
      emptyConfig={emptyConfig}
      {...rest}
    >
      {children}
    </BasicApeironPagingDataGrid>
  ) : (
    <></>
  )
}

type SelectedItem = any

export type AgDatasourceResult = {
  data: unknown[]
  total?: number
}

export type ApeironDataGridRef = {
  setColumnVisible: (key: string | Column, visible: boolean) => void
  refreshData: () => void
}

type EmptyConfig = {
  buyURL: string
  rentURL: string
}

type BasicProps = {
  className?: string
  pageSize?: number
  children?: ReactNode
  loading?: boolean
  totalPage: number
  currentPage: number
  emptyConfig?: EmptyConfig
  onNextPage: () => void
  onPreviousPage: () => void
  onSubmitPage: (page: number) => void
  defaultColDef?: ColDef
  onGridReady?: (event: GridReadyEvent) => void
} & AgGridReactProps

type Props = AgGridReactProps & {
  autoFetch?: boolean
  children?: ReactNode
  emptyConfig?: EmptyConfig
  gridRef?: Ref<ApeironDataGridRef>
  loading?: boolean
  pageSize?: number
  selectedIds?: string[]
  fetch: (page: number, pageSize: number) => Promise<AgDatasourceResult>
  onSelectionChangedByPage?: (selectedRows: SelectedItem[]) => void
}

export default ApeironPagingDataGrid
