import {
  createStrictContext,
  CurrencyExchangeRatePool,
  CurrencyMoneyType,
  CurrencyOnChainTokenContract,
  CurrencyToken,
  CurrencyTokenType,
  EMPTY_CURRENCY,
  MayNull,
  useChainSwitcher,
  useGetCurrencyInfo,
  useWeb3,
  WETH_REFRESH_CURRENCY_RATE_DURATION
} from '@apeiron/library'
import CONTRACT from '@src/contracts/config'
import useGetTokenPriceToUSD from '@src/hooks/graphql/useGetTokenPriceToUSD'
import {
  DETAIL_USD_OPTIONS,
  formatNumberString,
  FormatPriceOptions
} from '@src/util/apeiron/formatNumberString'
import * as R from 'ramda'
import { ReactNode, useCallback, useEffect, useMemo } from 'react'

const [ContextProvider, useCurrencyContext] =
  createStrictContext<Context>('Currency')

export { useCurrencyContext }

export const CurrencyProvider = (props: Props) => {
  const { children } = props

  const contractInfo = useMemo((): {
    [key in CurrencyTokenType]?: CurrencyOnChainTokenContract
  } => {
    return {
      [CurrencyTokenType.Anima]: CONTRACT.CONJUNCT.ANIMA,
      [CurrencyTokenType.Aprs]: CONTRACT.CONJUNCT.APRS,
      [CurrencyTokenType.BlackHole]: CONTRACT.CONJUNCT.BLACK_HOLE,
      [CurrencyTokenType.Unspecified]: CONTRACT.WETH,
      [CurrencyTokenType.Usdc]: CONTRACT.USDC,
      [CurrencyTokenType.Weth]: CONTRACT.WETH,
      [CurrencyTokenType.WRon]: CONTRACT.WRON
    }
  }, [])

  const { getContractAddress } = useChainSwitcher()

  const { fromWei } = useWeb3()

  const { data: currencyInfo, fetch: fetchCurrencyInfo } = useGetCurrencyInfo()

  const { data: fetchedExchangeData, refetch: refetchExchangeRate } =
    useGetTokenPriceToUSD()

  const exchangeRatePool = useMemo((): CurrencyExchangeRatePool => {
    if (R.isNotNil(fetchedExchangeData)) {
      const extractRatio = (type: CurrencyTokenType) => {
        const currency = R.find(R.propEq(type, 'type'), fetchedExchangeData)

        return R.propOr(0, 'ratio', currency)
      }

      const tokenKeys = Object.values(CurrencyTokenType)

      const moneyKeys = Object.values(CurrencyMoneyType)

      let mapping: CurrencyExchangeRatePool = {}

      R.forEach((tokenType: CurrencyTokenType) => {
        let moneyMap = {}

        R.forEach((moneyType: CurrencyMoneyType) => {
          moneyMap = R.assoc(moneyType, extractRatio(tokenType), moneyMap)
        }, moneyKeys)

        mapping = R.assoc(tokenType, moneyMap, mapping)
      }, tokenKeys)

      return mapping
    }

    return {}
  }, [fetchedExchangeData])

  useEffect(() => {
    const timer = setInterval(
      refetchExchangeRate,
      WETH_REFRESH_CURRENCY_RATE_DURATION
    )

    return () => clearInterval(timer)
  }, [refetchExchangeRate])

  const getCurrency = useCallback(
    (type: MayNull<CurrencyTokenType>): MayNull<CurrencyToken> =>
      R.propOr(null, type || '', currencyInfo),
    [currencyInfo]
  )

  const getCurrencyContract = useCallback(
    (type: CurrencyTokenType): CurrencyOnChainTokenContract => {
      return R.propOr(
        {
          GET_USER_BALANCE: CONTRACT.BLANK,
          GET_USER_ALLOWANCE: CONTRACT.BLANK,
          REQUEST_ALLOWANCE: CONTRACT.BLANK
        },
        type,
        contractInfo
      )
    },
    [contractInfo]
  )

  const getCurrencyAddress = useCallback(
    (type: CurrencyTokenType): MayNull<string> => {
      const addressMap = getCurrencyContract(type).GET_USER_ALLOWANCE.address

      return getContractAddress(addressMap)
    },
    [getContractAddress, getCurrencyContract]
  )

  const getApeironPrice = useCallback(
    (
      type: MayNull<CurrencyTokenType>,
      amount: Amount,
      options: FormatPriceOptions = {}
    ): string => {
      if (R.isNil(amount)) {
        return EMPTY_CURRENCY
      }

      const currency = getCurrency(type)

      const tokenPrice = fromWei(String(amount), currency?.decimals || 0)

      return formatNumberString(tokenPrice, options)
    },
    [fromWei, getCurrency]
  )

  // show price with all integers and 6 decimal places
  const getApeironDetailPrice = useCallback(
    (amount: number, maxDecimal: number = 18): string => {
      if (R.isNil(amount)) {
        return EMPTY_CURRENCY
      }

      if (amount === 0) {
        return '0'
      }

      return formatNumberString(String(amount), {
        maxLength: 10,
        maxDecimal: maxDecimal,
        minNumberWithoutENotation: 5e-11,
        allowENotation: true,
        allowedLargeNumberAbbr: false,
        priorities: ['maxLength', 'maxDecimal']
      })
    },
    []
  )

  const getApeironPriceWithUnit = useCallback(
    (
      type: MayNull<CurrencyTokenType>,
      amount: Amount,
      options: FormatPriceOptions = {}
    ): string => {
      const price = getApeironPrice(type, amount, options)

      const currency = getCurrency(type)

      return R.isNotNil(currency) ? `${price} ${currency.name}` : price
    },
    [getApeironPrice, getCurrency]
  )

  const getExchangeRate = useCallback(
    (
      from: CurrencyTokenType = CurrencyTokenType.Weth,
      to: CurrencyMoneyType = CurrencyMoneyType.Usd
    ) => {
      return R.pathOr(0, [from, to], exchangeRatePool)
    },
    [exchangeRatePool]
  )

  const getUSDPrice = useCallback(
    (
      type: MayNull<CurrencyTokenType>,
      amount: Amount,
      weiConvert: boolean,
      options: FormatPriceOptions = {}
    ): string => {
      const currency = getCurrency(type)

      if (R.isNil(currency)) {
        return '0'
      }

      const exchangeRate = getExchangeRate(
        type || CurrencyTokenType.Weth,
        CurrencyMoneyType.Usd
      )

      const nonWeiAmount = weiConvert
        ? fromWei(String(amount), currency.decimals)
        : String(amount)

      const usdPrice = exchangeRate * parseFloat(nonWeiAmount)

      return formatNumberString(usdPrice.toString(), {
        ...DETAIL_USD_OPTIONS,
        ...options
      }) // ? currency = null (avoid currency convertion as price already in USD)
    },
    [fromWei, getCurrency, getExchangeRate]
  )

  const convertCurrencyPrice = useCallback(
    (price: number, from: CurrencyTokenType, to: CurrencyTokenType): number => {
      if (from === to) {
        return price
      }

      const fromRateInUsd = getExchangeRate(from, CurrencyMoneyType.Usd)

      const toRateInUsd = getExchangeRate(to, CurrencyMoneyType.Usd)

      if (toRateInUsd === 0) {
        return 0
      }

      return (price * fromRateInUsd) / toRateInUsd
    },
    [getExchangeRate]
  )

  const convertCurrencyToUsd = useCallback(
    (price: number, from: CurrencyTokenType): number => {
      const rateInUsd = getExchangeRate(from, CurrencyMoneyType.Usd)

      return price * rateInUsd
    },
    [getExchangeRate]
  )

  useEffect(() => {
    fetchCurrencyInfo()
  }, [])

  return (
    <ContextProvider
      value={{
        getApeironPrice,
        getApeironDetailPrice,
        getApeironPriceWithUnit,
        getCurrency,
        getCurrencyAddress,
        getCurrencyContract,
        getExchangeRate,
        getUSDPrice,
        convertCurrencyPrice,
        convertCurrencyToUsd
      }}
    >
      {children}
    </ContextProvider>
  )
}

type Amount = MayNull<number | string>

type Context = {
  getApeironPrice: (
    type: MayNull<CurrencyTokenType>,
    amount: Amount,
    option?: FormatPriceOptions
  ) => string
  getApeironDetailPrice: (amount: number, maxDecimal?: number) => string
  getApeironPriceWithUnit: (
    type: MayNull<CurrencyTokenType>,
    amount: Amount,
    option?: FormatPriceOptions
  ) => string
  getCurrency: (type: MayNull<CurrencyTokenType>) => MayNull<CurrencyToken>
  getCurrencyAddress: (type: CurrencyTokenType) => MayNull<string>
  getCurrencyContract: (type: CurrencyTokenType) => CurrencyOnChainTokenContract
  getExchangeRate: (from?: CurrencyTokenType, to?: CurrencyMoneyType) => number
  getUSDPrice: (
    type: MayNull<CurrencyTokenType>,
    amount: Amount,
    weiConvert: boolean,
    options?: FormatPriceOptions | {}
  ) => string
  convertCurrencyPrice: (
    price: number,
    from: CurrencyTokenType,
    to: CurrencyTokenType
  ) => number
  convertCurrencyToUsd: (price: number, from: CurrencyTokenType) => number
}

type Props = {
  children: ReactNode
}
