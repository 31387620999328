import { FeatureFlagMap } from '@apeiron/library'

// ? naming start by "Use" or "Show"
export enum FeatureFlagType {
  // login
  ShowLoginGettingStarted = 0,

  // account
  ShowAccountSSO,
  ShowAccountQRCode,

  // battle demo
  ShowBattleDemoNews,
  ShowBattleDemoReleaseNote,
  ShowBattleDemoSoloPvELeaderboard,
  ShowBattleDemoGuildPvELeaderboard,

  // reward
  ShowRewardShop,
  ShowRewardMakeupCheckInByAnima,

  // planet
  ShowPlanetHistory,
  ShowPlanetRelic,
  ShowPlanetStat,

  // trade (buy / sell)
  ShowTradeRemark,
  ShowBuyWithAnimaOption,
  ShowBuyWithAprsOption,

  // rental
  ShowRentalSuggestedFee,

  // download
  ShowDownloadQRCode,

  // lootrush
  ShowLootRush
}

export const FEATURE_FLAG_MAP: FeatureFlagMap<FeatureFlagType> = {
  // login
  [FeatureFlagType.ShowLoginGettingStarted]: [],

  // account
  [FeatureFlagType.ShowAccountSSO]: [],
  [FeatureFlagType.ShowAccountQRCode]: [],

  // battle demo
  [FeatureFlagType.ShowBattleDemoNews]: ['development', 'alpha', 'staging'],
  [FeatureFlagType.ShowBattleDemoReleaseNote]: [],
  [FeatureFlagType.ShowBattleDemoSoloPvELeaderboard]: [
    'development',
    'alpha',
    'staging'
  ],
  [FeatureFlagType.ShowBattleDemoGuildPvELeaderboard]: [
    'development',
    'alpha',
    'staging'
  ],

  // reward
  [FeatureFlagType.ShowRewardShop]: [],
  [FeatureFlagType.ShowRewardMakeupCheckInByAnima]: [
    'development',
    'alpha',
    'staging'
  ],

  // planet
  [FeatureFlagType.ShowPlanetHistory]: [],
  [FeatureFlagType.ShowPlanetRelic]: [],
  [FeatureFlagType.ShowPlanetStat]: [],

  // trade (buy / sell)
  [FeatureFlagType.ShowTradeRemark]: [],
  [FeatureFlagType.ShowBuyWithAnimaOption]: [],
  [FeatureFlagType.ShowBuyWithAprsOption]: ['development', 'alpha', 'staging'],

  // rental
  [FeatureFlagType.ShowRentalSuggestedFee]: [],

  // download
  [FeatureFlagType.ShowDownloadQRCode]: [],

  // lootrush
  [FeatureFlagType.ShowLootRush]: ['development', 'alpha', 'staging']
}
